import React, { useState, useEffect, forwardRef, type ReactElement, useRef } from 'react';
import type { MessageDescriptor } from 'react-intl-next';
import { defineMessages, useIntl } from 'react-intl-next';
import { styled } from '@compiled/react';
import { Coordination } from '@atlassiansox/engagekit-ts';
import { NudgeSpotlight, type NudgeType } from '@atlassiansox/nudge-tooltip';

import { SpotlightPulse } from '@atlaskit/onboarding';
import { Inline, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { ScreenEvent } from '@confluence/analytics';
import { useSessionData } from '@confluence/session-data';
import { useCoordinationClient } from '@confluence/engagement-provider';
import { useFeatureDiscovery } from '@confluence/feature-discovery';
import { GenericErrorBoundary, Attribution } from '@confluence/error-boundary';

import { JPDTryEndLearnImage, JSMTryEndLearnImage } from './assets';

const i18n = defineMessages({
	heading: {
		defaultMessage: 'New product added',
		id: 'app-navigation.app-switcher-nudge-tooltip.heading',
		description:
			'Describes the heading of the tooltip that appears when a new product is added to the site.',
	},
	jsmContent: {
		defaultMessage:
			'Someone in your team has just started using Jira Service Management. It’s been added to your site for free.',
		id: 'app-navigation.app-switcher-nudge-tooltip.jsm-content',
		description: 'Description of the tooltip that appears when a new product is added to the site.',
	},
	jpdContent: {
		defaultMessage:
			'Someone in your team has just started using Jira Product Discovery. It’s been added to your site for free.',
		id: 'app-navigation.app-switcher-nudge-tooltip.jpd-content',
		description: 'Description of the tooltip that appears when a new product is added to the site.',
	},
	jsmPrimaryButtonText: {
		defaultMessage: 'Take a tour',
		id: 'app-navigation.app-switcher-nudge-tooltip.jsm-primary-button-text',
		description:
			'Label for the confluence and jsm primary button in the tooltip that appears when a new product is added to the site.',
	},
	jpdPrimaryButtonText: {
		defaultMessage: 'Show details',
		id: 'app-navigation.app-switcher-nudge-tooltip.jpd-primary-button-text',
		description:
			'Label for the jpd primary button in the tooltip that appears when a new product is added to the site.',
	},
	secondaryAction: {
		defaultMessage: 'Dismiss',
		id: 'app-navigation.app-switcher-nudge-tooltip.secondary-action',
		description:
			'Label for the secondary action in the tooltip that appears when a new product is added to the site.',
	},
});

type AppSwitcherNudgeType = {
	// This is the Engagement platform message ID of the particular product to handle with the Coordination client
	messageId: string;
	// Heading of the Spotlight card
	heading: MessageDescriptor;
	// Content of the spotlight card
	content: MessageDescriptor;
	// Image of the Spotlight card
	image: string;
	// Primary button text of the spotlight card
	primaryActionText: MessageDescriptor;
	// Primary click action of the spotlight card
	primaryOnClickAction: (
		environment: 'DEVELOPMENT' | 'PRODUCTION' | 'STAGING',
		orgId?: string,
		cloudId?: string,
	) => void;
};

type NudgeSpotlightRef = {
	toggleCardVisibility?: (visible: boolean) => void;
};

export const SOURCE = 'endUserActivateSpotlightScreen';

const StyledNudge: NudgeType = forwardRef(({ children, nudgeTestId, ...restProps }, ref) => (
	<NudgeWrapper {...restProps} ref={ref} data-testid={nudgeTestId}>
		{children}
	</NudgeWrapper>
));

export const APP_SWITCHER_NUDGES: Array<AppSwitcherNudgeType> = [
	{
		messageId: 'confluence-end-user-activated-jsm_v2',
		heading: i18n.heading,
		content: i18n.jsmContent,
		image: JSMTryEndLearnImage,
		primaryActionText: i18n.jsmPrimaryButtonText,
		primaryOnClickAction: () =>
			window.open('/secure/WelcomeToSD.jspa?decorator=jsd-onboarding', '_blank'),
	},

	{
		messageId: 'confluence-end-user-activated-jpd_v2',
		heading: i18n.heading,
		content: i18n.jpdContent,
		image: JPDTryEndLearnImage,
		primaryActionText: i18n.jpdPrimaryButtonText,
		primaryOnClickAction: (environment, orgId, cloudId) => {
			window.open(
				`https://${
					environment === 'PRODUCTION' ? 'admin.atlassian.com' : 'admin.stg.atlassian.com'
				}/o/${orgId}/products/jira-product-discovery/${cloudId}`,
				'_blank',
			);
		},
	},
];

const AppSwitcherNudge = ({
	nudge,
	fallback,
	children,
}: {
	nudge: AppSwitcherNudgeType;
	fallback: ReactElement;
	children: ReactElement;
}) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { environment, cloudId, orgId } = useSessionData();
	const [dismissed, setDismissed] = useState(false);
	const coordinationClient = useCoordinationClient();
	const { messageId } = nudge;

	// we have turned off the duplicate check for our messageIds in EP so that the nudge border will be shown repeatedly until
	// the user sees the spotlight. We then use the feature-delivery API to mark the user as having seen it, and stop showing
	// it again (even though the user will remain enrolled in the audience for it)
	const [persistedShowFeature, stopFeature] = useFeatureDiscovery(`${messageId}.seen`);
	// persistedShowFeature becomes false once stopFeature() is called, however, we're using that to just mark the spotlight as seen
	// and we don't want to dismiss the spotlight straight away, so we keep to show it
	const [showFeature, setShowFeature] = useState(persistedShowFeature);

	useEffect(() => {
		if (persistedShowFeature) {
			setShowFeature(true);
		}
	}, [persistedShowFeature]);

	const hideMessage = (messageId: string) => {
		setDismissed(true);
		void coordinationClient.stop(messageId);
		createAnalyticsEvent({
			type: 'sendTrackEvent',
			data: {
				action: 'dismissed',
				actionSubject: 'endUserActivateSpotlight',
				source: SOURCE,
				attributes: {
					messageId,
				},
			},
		}).fire();
	};

	const handleFireDismissButtonClickEvent = () => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'endUserActivateSpotlightDismissButton',
				source: SOURCE,
				attributes: {
					messageId,
				},
			},
		}).fire();
	};

	const handleFireTourButtonClickEvent = () => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'endUserActivateSpotlightTourButton',
				source: SOURCE,
				attributes: {
					messageId,
				},
			},
		}).fire();
	};

	const nudgeSpotlightRef = useRef<NudgeSpotlightRef>({});

	// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
	const isSSR = Boolean(process.env.REACT_SSR);

	return showFeature && !isSSR ? (
		<Coordination client={coordinationClient} messageId={messageId} fallback={fallback}>
			<>
				<ScreenEvent
					attributes={{ messageId }}
					name="endUserActivateSpotlightNudgeScreen"
					id="endUserActivateSpotlightNudgeScreenEvent"
				/>
				<NudgeSpotlight
					hidden={dismissed}
					setHidden={() => hideMessage(messageId)}
					heading={formatMessage(nudge.heading)}
					width={276}
					content={
						<>
							<ScreenEvent attributes={{ messageId }} name={SOURCE} id={`${SOURCE}-event`} />
							{formatMessage(nudge.content)}
						</>
					}
					image={
						<Inline xcss={imgStyles}>
							<img alt="" src={nudge.image} width={276} />
						</Inline>
					}
					actions={[
						{
							text: formatMessage(nudge.primaryActionText),
							onClick: () => {
								handleFireTourButtonClickEvent();
								nudge.primaryOnClickAction(environment, orgId, cloudId);
							},
						},
						{
							text: formatMessage(i18n.secondaryAction),
							onClick: () => {
								handleFireDismissButtonClickEvent();
								hideMessage(messageId);
							},
						},
					]}
					position="bottom-end"
					onOutsideClick={() => hideMessage(messageId)}
					nudgeTestId={`${nudge.messageId}-nudge`}
					cardTestId={`${nudge.messageId}-card`}
					showSpotlightCardOnFirstRender={false}
					onShow={stopFeature}
					nudge={StyledNudge}
					ref={nudgeSpotlightRef}
				>
					<SpotlightPulse
						testId={`${messageId}-nudge-pulsing-border`}
						radius={50}
						pulse={!dismissed}
						onFocus={() => {
							!dismissed && nudgeSpotlightRef.current?.toggleCardVisibility?.(true);
						}}
					>
						{children}
					</SpotlightPulse>
				</NudgeSpotlight>
			</>
		</Coordination>
	) : (
		fallback
	);
};

export const AppSwitcherNudgeToolTip = ({ children }: { children: ReactElement }) => (
	<GenericErrorBoundary attribution={Attribution.GROWTH_MULTIPLIERS} renderOnError={() => children}>
		{APP_SWITCHER_NUDGES.reduce(
			(acc, nudge) => (
				<AppSwitcherNudge nudge={nudge} fallback={acc}>
					{children}
				</AppSwitcherNudge>
			),
			children,
		)}
	</GenericErrorBoundary>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const NudgeWrapper = styled.div({
	// previous app switcher button inside have 2px margin left and right
	margin: `0 ${token('space.025')}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	button: {
		margin: 0,
		'&:hover, &:focus, &:active, &:focus-visible': { margin: 0 },
	},
});

const imgStyles = xcss({
	height: '154px',
	width: '276px',
	marginBottom: 'space.negative.050',
});
