import {
	createProviderWithCustomFetchData,
	type ExportedDataProvider,
} from './create-data-provider';
import { type AvailableProductsResponse } from '../../types';
import { fetchJsonWithNetworkRetries } from '../utils/fetch';
import { ExperienceMark } from '../utils/experience-tracker';
import { ASSETS_APP_KEY } from '../../constants';

export const APP_SWITCHER_BFF_AVAILABLE_PRODUCTS_ENDPOINT =
	'/gateway/api/app-switcher/api/available-products';

// TODO: remove this once Asset app becomes standalone app
const transformAppSwitcherBffResponse = (response: AvailableProductsResponse) => {
	if (response.switcherTeam25ExperimentCohort === 'test' && response.assetsAsAnAppEnabled) {
		response.sites.forEach((site) => {
			if (
				site.availableProducts.some(
					(product) => product.productType === 'JIRA_SERVICE_DESK' && product.isJsmPremium,
				)
			) {
				site.availableProducts.push({ productType: ASSETS_APP_KEY });
			}
		});
	}
	return response;
};

interface Params {
	cloudId?: string;
	product?: string;
}

export const appSwitcherBffAvailableProductsFetch = async ({ cloudId, product }: Params) => {
	const params = new URLSearchParams({ source: 'app-switcher-fe' });
	if (cloudId) {
		params.append('tenantid', encodeURIComponent(cloudId));
	}
	if (product) {
		params.append('product', encodeURIComponent(product));
	}

	const response = await fetchJsonWithNetworkRetries<AvailableProductsResponse>(
		`${APP_SWITCHER_BFF_AVAILABLE_PRODUCTS_ENDPOINT}?${params.toString()}`,
		{
			intervalsMS: [50, 200, 1000],
		},
		{
			headers: {
				'Atl-App-Switcher-Private-API': 'true',
			},
		},
	);

	return transformAppSwitcherBffResponse(response);
};

export const createAppSwitcherBffAvailableProductsProvider =
	(): ExportedDataProvider<AvailableProductsResponse> => {
		return createProviderWithCustomFetchData<AvailableProductsResponse, Params>(
			'appSwitcherBffAvailableProducts',
			ExperienceMark.API_APP_SWITCHER_BFF_AVAILABLE_PRODUCTS,
			appSwitcherBffAvailableProductsFetch,
		);
	};
