import type React from 'react';
import { prefetchAll } from './common/providers/instance-data-providers';
import { prefetchAvailableProducts } from './common/providers/products-data-provider';
import {
	prefetchJoinableSites,
	type JoinableSitesDataProvider,
} from './cross-join/providers/joinable-sites-data-provider';
import prefetchSwitcherBundles from './common/utils/prefetch-bundles';
import { type FeatureFlagProps } from './types';

type PrefetchTriggerProps = {
	cloudId?: string;
	product?: string;
	Container?: React.ElementType;
	joinableSitesDataProvider?: JoinableSitesDataProvider;
} & Partial<FeatureFlagProps>;

export const prefetch = (props: PrefetchTriggerProps) => {
	const { cloudId, product } = props;

	prefetchSwitcherBundles(product);
	prefetchAvailableProducts({ cloudId, product });
	prefetchJoinableSites(props.joinableSitesDataProvider);

	if (cloudId) {
		prefetchAll({ cloudId });
	}
};
