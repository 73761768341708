import React from 'react';

import { useSSRPlaceholderReplaceIdProp } from '@confluence/loadable';

import { AppSwitcherComponent } from './AppSwitcherComponent';

// useSSRPlaceholderReplaceIdProp is added to LOADABLE AFTER_PAINT priority elements to identify and exclude them
// from TTVC calculations if their size and position doesn't change after hydration

// adding loadable placeholderId around AppSwitcherComponent here to avoid adding it within AppSwitcherComponent (AtlasKit component)
export const AppSwitcherComponentWrapper = (props) => {
	const ssrPlaceholderIdProp = useSSRPlaceholderReplaceIdProp();
	return (
		<div data-vc="app-navigation-app-switcher" {...ssrPlaceholderIdProp}>
			<AppSwitcherComponent {...props} />
		</div>
	);
};
