import React from 'react';

import { type AvailableProductsResponse, type ProviderResults } from '../../types';
import { type DataProvider } from './create-data-provider';
import { createAppSwitcherBffAvailableProductsProvider } from './app-switcher-bff-available-products-provider';

type RealProvider = DataProvider<AvailableProductsResponse>;
const {
	fetchMethod: fetchAppSwitcherBffAvailableProducts,
	ProviderComponent: DefaultBffDataProviderComponent,
} = createAppSwitcherBffAvailableProductsProvider() as RealProvider;

interface Props {
	cloudId?: string;
	product?: string;
	children: (availableProducts: ProviderResults['availableProducts']) => React.ReactNode;
}

export const AvailableProductsProvider = ({ cloudId, product, children }: Props) => (
	<DefaultBffDataProviderComponent cloudId={cloudId} product={product}>
		{children}
	</DefaultBffDataProviderComponent>
);

export const prefetchAvailableProducts = fetchAppSwitcherBffAvailableProducts;
export const resetAvailableProducts = () => fetchAppSwitcherBffAvailableProducts.reset();
