import React from 'react';

import { type JoinableSitesResponse, type ProviderResults } from '../../types';
import { createJoinableSitesProvider } from './default-joinable-sites-provider';
import {
	type ExportedDataProvider,
	type DataProvider,
} from '../../common/providers/create-data-provider';

const { fetchMethod: fetchJoinableSites, ProviderComponent: DefaultDataProviderComponent } =
	createJoinableSitesProvider() as DataProvider<JoinableSitesResponse>;

export type JoinableSitesDataProvider = ExportedDataProvider<JoinableSitesResponse>;

export const JoinableSitesProvider = ({
	children,
	joinableSitesDataProvider,
	cloudId,
}: {
	children: (joinableSites: ProviderResults['joinableSites']) => React.ReactNode;
	joinableSitesDataProvider?: JoinableSitesDataProvider;
	cloudId?: string;
}) => {
	const CustomDataProviderComponent =
		joinableSitesDataProvider && joinableSitesDataProvider.ProviderComponent;

	const DataProviderComponent = CustomDataProviderComponent || DefaultDataProviderComponent;
	return <DataProviderComponent cloudId={cloudId}>{children}</DataProviderComponent>;
};

export const prefetchJoinableSites = (customProvider?: JoinableSitesDataProvider) => {
	if (customProvider) {
		(customProvider as DataProvider<JoinableSitesResponse>).fetchMethod({});
		return;
	}

	fetchJoinableSites({});
};

export const resetJoinableSites = (customProvider?: JoinableSitesDataProvider) => {
	if (customProvider) {
		(customProvider as DataProvider<JoinableSitesResponse>).fetchMethod.reset();
		return;
	}

	fetchJoinableSites.reset();
};
