import { postJson } from '../utils/fetch';
import asDataProvider from './as-data-provider';
import { Permissions, type UserPermissionResponse, type WithCloudId } from '../../types';
import { withCached } from '../utils/with-cached';
import withHandleOptionalCloudId from './with-handle-optional-cloud-id';
import { ExperienceMark } from '../utils/experience-tracker';

// Permissions api
type FetchPermissionParamsType = WithCloudId & {
	permissionId: Permissions;
};
const fetchPermission = withCached(({ cloudId, permissionId }: FetchPermissionParamsType) =>
	postJson<UserPermissionResponse>(`/gateway/api/permissions/permitted`, {
		permissionId,
		resourceId: `ari:cloud:platform::site/${cloudId}`,
	}).then((permission) => permission.permitted),
);

export const UserPermissionProvider = withHandleOptionalCloudId(
	asDataProvider(
		'permission',
		ExperienceMark.API_PERMISSIONS,
		fetchPermission,
		fetchPermission.cached,
	),
	false,
);

export const prefetchAll = ({ cloudId }: WithCloudId) => {
	fetchPermission({ cloudId, permissionId: Permissions.MANAGE });
};

export const resetAll = () => {
	fetchPermission.reset();
};
